var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", "text-center": "" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-center flex-column",
          staticStyle: { width: "100%" }
        },
        [
          _c("p", { staticClass: "mb-5" }, [
            _vm._v(
              "We are sorry, but it seems the page you requested does not currently exist."
            )
          ]),
          _c(
            "div",
            [
              _c(
                "v-btn",
                { attrs: { to: { name: "home-welcome" }, color: "secondary" } },
                [_vm._v("Go to Dashboard")]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }