<template>
    <v-container fill-height text-center>
        <div style="width:100%" class="d-flex justify-center flex-column">
            <p class="mb-5">We are sorry, but it seems the page you requested does not currently exist.</p>
            <div><v-btn :to="{ name: 'home-welcome' }" color="secondary">Go to Dashboard</v-btn></div>
        </div>
    </v-container>
</template>

<script>
export default {}
</script>
